
.left-block{
    padding: 18px;
    background-color: #19459E;
    border-radius: 5px 20px 20px 20px;
    display: inline-block;
    max-width: 350px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.right-block{
    padding: 18px;
    background-color: #fff;
    border-radius: 20px 5px 20px 20px;
    display: inline-block;
    max-width: 350px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.bg-blue{
    background-color: #19459E;
}


.img-pro{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
}

.img-pro img{
    width: 100%;
    height: 100%;
}


.fs-14{
    font-size: 14px;
}

.time{
    font-size: 12px;
    color: #fff;
}

textarea{
    resize: none;
}

.form-control:focus{
    box-shadow: none;
}

.h-fix{
    height: 400px;
    overflow-y: auto;
}


.h-fix::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.h-fix::-webkit-scrollbar
{
    width: 5px;
    background-color: #F5F5F5;
}

.h-fix::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

.cust-pill .nav-link{
    border-bottom: 1px solid transparent;
}

.cust-pill .nav-link.active, .cust-pill .show>.nav-link {
    background-color: transparent;
    border-color: #19459E;
}

.accord-cust .accordion-button:not(.collapsed){
    background-color: #19459E;
    box-shadow: none !important;
    color: #fff;
}

.accordion-button:not(.collapsed)::after{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:focus{
    box-shadow: none;
}
.question-desc{
    font-weight: 100;
    color: brown;
    font-size: 11px;
    margin-left: 10px;
}
.question-desc, p{
    margin: 0;
    padding: 0;
}

.custom-switch  {
    /* background-color: #4e7661 !important; */
    border-radius: 50px !important;
    margin-bottom: 5px;
}
.custom-switch.ant-switch-checked {
    background-color: #4e7661 !important;
}
.tracker-title{
    color: black;
    font-size: 15px;
}
.option-section{
    padding-left: 15px;
}
.selected-option-wrong {
    display: inline-block;
    background-color: rgb(247, 246, 246); /* Optional: for visual demonstration */
    border: 1px solid salmon;
    padding: 3px;

}
.selected-option-correct {
    display: inline-block;
    background-color: rgb(247, 246, 246); /* Optional: for visual demonstration */
    border: 1px solid green;
    padding: 3px;

}

  

  

