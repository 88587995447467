.h-100 {
    height: 100% !important;
}
.ps-3 {
    padding-left: 1rem !important;
}
.calendar{
    width: 3.125rem;
    line-height: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    border-radius: 0.375rem;
}
.calendar .calendar-month{
    background-color: #e63757;
    color: #fff;
    border-radius: 0.375rem 0.375rem 0 0;
    padding: 0.25rem;
    font-weight: 600;
}
.calendar .calendar-day{
    font-size: 1.728rem;
    padding: 0.25rem;
    border: 1px solid #f0b8b8;
    border-top: 0;
    border-radius: 0 0 0.375rem 0.375rem;
    color: #9da9bb;
    font-weight: 700;
}
.meeting-title{
    color: #2c7be5;
}
.flex-1 {
    flex: 1;
}
.fs-9 {
    font-size: 1rem !important;
}
.ps-1 {
    padding-left: 0.25rem !important;
}
.text-700 {
    opacity: 1;
    color:rgb(87 93 102) !important;
}
.text-1000 {
    opacity: 1;
    color:rgb(35, 46, 60) !important;
}

.border-bottom {
    border-bottom: 0.123rem dashed #d8e2ef !important;
}
p{
    font-size: 13px;
    font-weight: 100;
}
.bolder{
    font-weight: 700 !important;
}
.btn-icon{
    color: #85827f;
    font-weight: 100;
}
.ql-container {
    min-height: 200px !important; /* Set your desired minimum height here */
  }