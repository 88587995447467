.notification-header{
    padding: 5px;
    background: green;;
}
.notification-title{
    font-size: 17px;
    color: white;
}
.btn-mark-read:hover{
    text-decoration: none;
}
.notification-bar:hover{
    background-color: #f8f8f8;
}
