.data-loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #096d20;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .data-loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-left: 4px solid #FF3D00;
    border-bottom: 4px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

  .bd-link{
    font-size: 1.5rem !important;
    margin-right: 10px;
  }
  .right-box{
    float: right;
    width: 100%;
    box-sizing: border-box;

  }
  .left-box-content{
    background: #eaeaea;
    color: black;
    padding: 8px;
    width: fit-content;
    border-radius: 10px;
    margin-bottom: 0px;

  }
  .right-box-content{
    background: #ceeef6c9;
    color: black;
    padding: 8px;
    width: fit-content;
    border-radius: 10px;
    margin-bottom: 0px;
    word-wrap: break-word;
  }
  .chat-inner {
    height: 400px;
    overflow-y: scroll; /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */
}

.chat-history::-webkit-scrollbar {
    width: 4px;  /* Very small width for the scrollbar */
}

.chat-history::-webkit-scrollbar-thumb {
    background: #a74646;  /* Color of the scrollbar thumb */
    border-radius: 10px;  /* Rounded scrollbar thumb */
}

.chat-history::-webkit-scrollbar-thumb:hover {
    background: #555;  /* Color of the scrollbar thumb on hover */
}

.chat-history::-webkit-scrollbar-track {
    background: #f1f1f1;  /* Color of the scrollbar track */
}
.chat-input {
  display: flex;
  align-items: center;
  padding: 15px;
  border-top: 1px solid #ccc;
}

.chat-input textarea {
  width: 100%;
  height: 100px;
  resize: none;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'Times New Roman', Times, serif;
  line-height: 1.5;
  margin-right: 10px;
}

.chat-input button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 36px;
  background-color: #cacd52;
  color: white;
  border: none;
  border-radius: 30%;
  cursor: pointer;
}



.chat-input button:hover {
  background-color: #45a049;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.steps .step {
  display: block;
  width: 100%;
  text-align: center;
}
.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  text-align: center;
}
.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  border: 1px solid #e1e7ec;
  border-radius: 30px;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 38px;
  line-height: 42px;
  z-index: 5;
}
.steps .step .step-icon  .fa{
  font-size: 25px;
}
.steps .step.completed .step-icon {
  border-color: #88aaf3;
  background-color: #88aaf3;
  color: #fff;
}
.steps .step.completed .step-icon-wrap::after {
  background-color: #88aaf3;
}
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -15px;
  background-color: #e1e7ec;
  content: "";
  z-index: 1;
  right: 0;
}
.steps .step.completed .step-icon-wrap::before {
  background-color: #88aaf3;
}
.steps .step .step-icon-wrap::before {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -15px;
  background-color: #e1e7ec;
  content: "";
  z-index: 1;
  left: 0;
}
.steps .step .step-icon-wrap::before {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -15px;
  background-color: #e1e7ec;
  content: "";
  z-index: 1;
  left: 0;
}
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -15px;
  background-color: #e1e7ec;
  content: "";
  z-index: 1;
  right: 0;
}
.steps .step .step-title {
  margin-top: 16px;
  margin-bottom: 0;
  color: #606975;
  font-size: 14px;
  font-weight: 500;
}
.card-mini {
  border: 1px solid #f3f3f3;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
}
.card-mini .card-body {
  padding: 1.63rem;
}
.card-mini .card-body h2, .card-mini .card-body .h2 {
  color: #56606e;
  font-size: 23px;
}
.dash-card .card-body span {
  position: absolute;
  right: 20px;
  bottom: 25px;
  font-size: 30px;
  border-radius: 15px;
  width: 50px;
  height: 50px;
  text-align: center;
 
  color: #ffffff;
  line-height: inherit;
}
.bg-blue{
  background: #88aaf3;
}
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  box-shadow: none !important;
}
.note-card{
  border-color: transparent;
  border-radius: 10px;
}
.note-date{
  font-size: 12px;
  color: #6c757d;
}
.note-title {
  font-size: 15px;
  text-transform:capitalize;
  color: black;
}
.note-edit-icon {
  font-size: 15px;
  color: #090a09;
  padding: 0px;
  border-radius: 15px;
}
.note-btn-add{
  border: 1px solid gray;
  border-radius: 10px;
  border-style: dashed;
}
.note-product{
  font-size: 13px;
}
.note-body{
  font-size: 13px;
  margin-top: 10px !important;
  color: #201f1f;
}
.user-card{
  border-color: transparent;
  border-radius: 15px;
  margin-bottom: 10px;
  background: #f6f1ea;
}
.username-card{
  font-size: 25px;
  font-weight: 100;
  font-family: system-ui;
}
.user-desc-card{
  font-style: italic;
    letter-spacing: .1rem;
    font-family: serif;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed{
  background: #f5f5f5 !important;
  border: 1px solid gray;
}
.accordion-button:not(.collapsed){
  border: none  !important;
}
.dotted-border {
  border: 1px dashed #1890ff; /* Adjust color and width as needed */
  padding: 8px 15px; /* Adjust padding as needed */
  cursor: pointer; /* Ensure cursor changes on hover */
}



.chat {
  float: left;
  background: #F2F5F8;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #434651;
  width: 100%;
}
.chat .chat-header {
  padding: 20px;
  border-bottom: 2px solid white;
}
.chat .chat-header img {
  float: left;
}
.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
  margin-top: 6px;
}
.chat .chat-header .chat-with {
  font-weight: bold;
  font-size: 16px;
}
.chat .chat-header .chat-num-messages {
  color: #92959E;
}
.chat .chat-header .fa-star {
  float: right;
  color: #D8DADF;
  font-size: 20px;
  margin-top: 12px;
}
.chat .chat-history {
  padding: 30px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: scroll;
  height: 575px;
}
.chat .chat-history .message-data {
  margin-bottom: 15px;
}
.chat .chat-history .message-data-time {
  color: #a8aab1;
  padding-left: 6px;
}
.chat .chat-history .message {
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 30px;
  width: 90%;
  position: relative;
}
.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #86BB71;
  border-width: 10px;
  margin-left: -10px;
}
.chat .chat-history .my-message {
  background: #86BB71;
}
.chat .chat-history .other-message {
  background: #94C2ED;
}
.chat .chat-history .other-message:after {
  border-bottom-color: #94C2ED;
  left: 93%;
}
.chat .chat-message {
  padding: 30px;
}
.chat .chat-message textarea {
  width: 100%;
  border: none;
  padding: 10px 20px;
  font: 14px/22px "Lato", Arial, sans-serif;
  margin-bottom: 10px;
  border-radius: 5px;
  resize: none;
}
.chat .chat-message .fa-file-o, .chat .chat-message .fa-file-image-o {
  font-size: 16px;
  color: gray;
  cursor: pointer;
}
.chat .chat-message button {
  float: right;
  color: #94C2ED;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: #F2F5F8;
}
.chat .chat-message button:hover {
  color: #75b1e8;
}

.online, .offline, .me {
  margin-right: 3px;
  font-size: 10px;
}

.online {
  color: #86BB71;
}

.offline {
  color: #E38968;
}

.me {
  color: #94C2ED;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.custom-tooltip {
  background-color: rgb(205, 202, 202) !important; /* Change to your desired color */
  color: black !important;
  border-radius: 15px;
  border: 1px solid rgb(39, 39, 39);
  padding: 10px;
  opacity: 1 !important; /* Ensure it's not transparent */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.file-dropzone{
  border: 1px dashed #b2aeae;
  padding: 40px;
  border-radius: 10px;
}
.file-dropzone, p{
  color: #9ea4a2;
}
.blinking {
  -webkit-animation: blink 1s ease infinite;
  -moz-animation: blink 1s ease infinite;
  -ms-animation: blink 1s ease infinite;
  -o-animation: blink 1s ease infinite;
  animation: blink 1s ease infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.text-danger-glow-red {
  color: orange;
  text-shadow: 0 0 20px #f00, 0 0 30px #f00, 0 0 40px #f00, 0 0 50px #f00, 0 0 60px #f00, 0 0 70px #f00, 0 0 80px #f00;
}
.text-danger-glow-green {
  color: #08780c;
  text-shadow: 0 0 20px rgb(20, 202, 102), 0 0 30px rgb(20, 202, 102), 0 0 40px rgb(20, 202, 102), 0 0 50px rgb(20, 202, 102), 0 0 60px rgb(20, 202, 102), 0 0 70px rgb(20, 202, 102), 0 0 80px rgb(20, 202, 102);
}
.text-danger-glow-purple {
  color: purple;
  text-shadow: 0 0 20px #800080, 0 0 30px #800080, 0 0 40px #800080, 0 0 50px #800080, 0 0 60px #800080, 0 0 70px #800080, 0 0 80px #800080;
}

.blink {
  font-size: 8px;
  animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}
@keyframes blinker {  
  from { opacity: 1; }
  to { opacity: 0; }
}
.alert-custom {
  --bs-alert-color: #fff; /* Text color */
  --bs-alert-bg: #000; /* Background color (black) */
  --bs-alert-border-color: #444; /* Border color (dark gray) */
  --bs-alert-link-color: #0d6efd;
  padding: 1rem; /* Adjust padding as needed */
  border: 1px solid var(--bs-alert-border-color);
  border-radius: 0.25rem; /* Border radius for rounded corners */
  background-color: var(--bs-alert-bg);
  color: var(--bs-alert-color);
}
.react-datepicker-wrapper{
  width: 100% !important;
}

.question_description p {
  color: black !important;
  font-size: 14px !important;
}


