*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Syne", sans-serif;
}

:root{
    --main-color: #d0162c;
    --white-color:#ffffff;
    --black:#000;
}

.bg-img{
    /* background-image: url('../images/bg-1.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;
}

.right-block-box{
    padding: 18px;
    background-color: var(--main-color);
    border-radius: 35px 35px 0px 35px;
    display: inline-block;
}

.fs-14{
    font-size: 14px;
}
.fs-16{
    font-size: 16px;
}

/*.height-fix{
    height: 600px;
    overflow-y: auto;
}

.height-fix::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.height-fix::-webkit-scrollbar
{
    width: 6px;
    background-color: #F5F5F5;
}

.height-fix::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}*/

.w-300{
    width: 300px;
    max-width: 100%;
}

.w-500{
    width: 500px;
    max-width: 100%;
}

.pad-14-20{
    padding: 10px 20px;
}

.form-select:focus{
    box-shadow: none !important;
    border-color:var(--main-color);
}

.bordr-color-main{
    border-color: var(--main-color) !important;
}

.colorchange:checked{
    background-color: var(--main-color) !important;
    border-color: var(--main-color) !important;
}

.colorchange:focus{
    box-shadow: none !important;
}

.resize-none{
    resize: none;
}

.resize-none:focus{
    box-shadow: none;
}

.pointer{
    cursor: pointer;
}

.line{
    margin-right: 10px;
    width: 50px;
    height: 3px;
    background: var(--main-color);
    border-radius: 12px;
}

.smdot{
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--main-color);
    margin-right: 10px;
}


/*progress bar css*/
.progress-container {
    position: relative;
    width: 150px;
    height: 150px;
}

.progress-circle {
    position: relative;
    transform: rotate(-90deg);
}
.CircularProgressbar {
    width: 150px !important;
    height: 150px !important;
}

.progress-background,
.progress-foreground {
    fill: none;
    stroke-width: 10;
}

.progress-background {
    stroke: #ddd;
}

.progress-foreground {
    stroke: var(--main-color);
    stroke-linecap: round;
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
    transition: stroke-dashoffset 0.5s;
}

.progress-value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
}
.header-section{
    padding-top: 10px;
    padding-bottom: 10px;
}
.main-section{
    margin-top:100px;
}
.main-heading{
    font-size: 1.75rem !important;
    font-weight: 500;
}

.question_description {
    padding-left: 15px;
    margin-bottom: 10px;
}
.question_description p{
    margin: 0px;
    padding: 0px;
}
.card-thanks{
    border-color: transparent;
    border-radius: 20px;
}
.success-title{
    font-size: 25px;
    font-family: 'Urbanist';
    color: brown;
}
.success-dec{
    color: #323436;
    font-family: 'Urbanist';
}
.font-theme{
    font-family: "Urbanist", sans-serif !important;
}
.question-warning{
    border-radius: 30px;
    text-align: center;
    padding: 26px;
    border-color: red;
    margin-top: 20px !important;
    color: #3a3838;
}